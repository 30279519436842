import React, { useContext, useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import AppRout from "./components/cmp-app-route/AppRoute";
import Login from "./containers/customer/customer-login/Login";
import LoginScheduler from "./containers/scheduler/scheduler-login/Login";
import AdminLogin from "./containers/admin/admin-login/Login";
import DashboardAdmin from "./containers/admin/dashboard/Dashboard";
import CustomerManagement from "./containers/admin/customer-management/CustomerManagement";
import ViewCustomer from "./containers/admin/customer-management/view-customer/view-customer";
import SchedulerManagement from "./containers/admin/scheduler-management/SchedulerManagement";
import ViewScheduler from "./containers/admin/scheduler-management/view-scheduler/view-scheduler";
import BuildingManagment from "./containers/admin/building-management/BuildingManagment";
import ViewBuilding from "./containers/admin/building-management/view-building/view-building";
import AddBuilding from "./containers/admin/add-building/add-building";
import UpdateBuilding from "./containers/admin/update-building/update-building";
import PinManagement from "./containers/admin/pin-management/pin-management";
import Home from "./containers/customer/customer-home/Home";
import OrderRequestForm from "./containers/customer/order-request-form/OrderRequestForm";
import ViewOrderDetail from "./containers/customer/view-order-detail/ViewOrderDetail";
import OrderReview from "./containers/customer/order-review/order-review";
import SchedulerHome from "./containers/scheduler/scheduler-home/Home";
import CompleteOrder from "./containers/scheduler/complete-order/complete-order";
import NotFound from "./components/cmp-not-found/cmp-not-found";
import { AppContext } from "./Context/AppContext";
import TechnicianManagement from "./containers/admin/technician-managment/TechnicianManagement";
import ViewTechnician from "./containers/admin/technician-managment/view-technician/view-technician";
import TechLogin from "./containers/technician/tech-login/Login";
import TechHome from "./containers/technician/tech-home/Home";
import FollowUpRequests from "./containers/customer/followup-requests/FollowUpRequests";
import FollowUpRequestsAdmin from "./containers/admin/followup-requests/FollowUpRequestsAdmin";
import ViewOrderDetailAdmin from "./containers/admin/view-order-detail/ViewOrderDetailAdmin";
import OrderRequestFormScheduler from "./containers/scheduler/order-request-form/OrderRequestForm";
import BuildingHistory from "./containers/customer/building-history/BuildingHistory";
import ScheduleOrders from "./containers/scheduler/scheduler-orders/SchedulerOrders";
import OrderRequestDraft from "./containers/scheduler/order-request-draft/OrderRequestDraft";
import DraftRequestsAdmin from "./containers/admin/draft-request/DraftRequestsAdmin";
import OrderAutoFollowUp from "./containers/customer/auto-followup-order/OrderAutoFollowUp";
import SettingsManagement from "./containers/admin/settings-page/SettingsManagement";
import BuildingHeatmap from "./containers/scheduler/BuildingHeatmap/BuildingHeatmap";
import PortalDocuments from "./containers/admin/portal-documents/PortalDocuments";
import FolderDocuments from "./containers/admin/portal-documents/view-folder-documents/view-folder-documents";
import CustomerPortalDocuments from "./containers/customer/customer-portal-documents/customer-portal-documents";
import InvalidCommentsMissingUnits from "./containers/admin/invalid-comments-missing-units";
import BuildingOrders from "./containers/customer/BuildingOrders";
import CriticalList from "./containers/scheduler/CriticalList/CriticalList";
import BuildingImpersonate from "./containers/scheduler/BuildingImpersonate/BuildingImpersonate";
import ImpersonateOrderRequestForm from "./containers/scheduler/BuildingImpersonate/order-request-form/OrderRequestForm";
import ImpersonateOrderReview from "./containers/scheduler/BuildingImpersonate/order-review/order-review"
import NOTES from "./containers/scheduler/BuildingImpersonate/notes/notes";
import Buildingheatmapreport from "./containers/customer/customer-home/buildingheatmap-report/buildingheatmap-report";
// import TechLogin from './containers/technician/tech-login/Login';

export default function Routes({ appProps }) {
  /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
  const { userInfo, selectedBuilding } = useContext(AppContext);
  const [selected_building] = selectedBuilding;

  const [user] = userInfo;
  const history = useHistory();
  const navigateBasedOnRole = (role, select_id, from, history) => {
    const params = new URLSearchParams({ s_id: select_id });
  
    switch (role) {
      case 'admin':
        history.replace('/dashboard');
        break;
      case 'scheduler':
        history.replace('/scheduler-home');
        break;
      case 'technician':
        history.replace('/technician');
        break;
      case 'customer':
        history.replace({
          pathname: '/home',
          search: params.toString(),
        });
        break;
      default:
        history.replace(from?.pathname || '/');
    }
  };
  
  const handleNavigation = (user, history) => {
    const select_id = localStorage.getItem('s_id');
    const from = history.location.state?.from;
    
    if (from) {
      if (
        from.pathname.includes('/home') ||
        [
          '/order-request',
          '/order-review',
          '/order-detail',
          '/building-heatmap/',
        ].includes(from.pathname)
      ) {
        navigateBasedOnRole(user.role, select_id, from, history);
      } else if (['/scheduler-home', '/complete-order'].includes(from.pathname)) {
        navigateBasedOnRole(user.role, select_id, from, history);
      } else if (from.pathname === '/technician-home') {
        navigateBasedOnRole(user.role, select_id, from, history);
      } else if (from.pathname.startsWith('/building-orders')){
        history.replace(from.pathname);
        //navigateBasedOnRole(user.role, select_id, null, history);
      } else {
        history.replace(from.pathname);
        //navigateBasedOnRole(user.role, select_id, null, history);
      }
    } else {
      navigateBasedOnRole(user.role, select_id, null, history);
    }
  };

  /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
  useEffect(() => {
    localStorage.setItem("to_url", JSON.stringify(history.location));
  });
  useEffect(() => {
    if (user) {
      handleNavigation(user, history);
    }
  }, [user, history]);
  // useEffect(() => {
  //   console.log('user', user);
  //   if (user) {
  //     const select_id = localStorage.getItem("s_id");
  //     if (history.location.state) {
  //       const { from } = history.location.state;
  //       const { role } = user;
  //       if (
  //         from.pathname.includes("/home") ||
  //         from.pathname === "/order-request" ||
  //         from.pathname === "/order-review" ||
  //         from.pathname === "/order-detail" ||
  //         from.pathname === "/building-history/" ||
  //         from.pathname === "/building-heatmap/"
  //       ) {
  //         console.log('role1', role);
  //         if (role === "admin") {
  //           history.replace("/dashboard");
  //         } else if (role === "scheduler") {
  //           history.replace("/scheduler-home");
  //         } else if (role === "technician") {
  //           history.replace("/technician");
  //         } else {
  //           history.replace(from.pathname);
  //         }
  //       } else if (
  //         from.pathname === "/scheduler-home" ||
  //         from.pathname === "/complete-order"
  //       ) {
  //         console.log('role2', role);
  //         if (role === "admin") {
  //           history.replace("/dashboard");
  //         } else if (role === "customer") {
  //           const params = new URLSearchParams({
  //             ["s_id"]: select_id,
  //           });
  //           history.replace({
  //             pathname: `/home`,
  //             search: params.toString(),
  //           });
  //           // history.replace(
  //           //   `/home?${
  //           //     new URLSearchParams(history.location.search).get("s_id") || ""
  //           //   }`
  //           // );
  //         } else if (role === "technician") {
  //           history.replace("/technician");
  //         } else {
  //           history.replace(from.pathname);
  //         }
  //       } else if (from.pathname === "/technician-home") {
  //         if (role === "admin") {
  //           history.replace("/dashboard");
  //         } else if (role === "customer") {
  //           const params = new URLSearchParams({
  //             ["s_id"]: select_id,
  //           });
  //           history.replace({
  //             pathname: `/home`,
  //             search: params.toString(),
  //           });
  //           // history.replace(
  //           //   `/home?${
  //           //     new URLSearchParams(history.location.search).get("s_id") || ""
  //           //   }`
  //           // );
  //         } else if (role === "scheduler") {
  //           history.replace("/scheduler-home");
  //         } else {
  //           history.replace(from.pathname);
  //         }
  //       } else {
  //         history.replace(from.pathname);
  //       }
  //     } else {
  //       const { role } = user;
  //       if (role === "admin") history.replace("/dashboard");
  //       else if (role === "customer") {
  //         const params = new URLSearchParams({
  //           ["s_id"]: select_id,
  //         });
  //         history.replace({
  //           pathname: `/home`,
  //           search: params.toString(),
  //         });
  //         // history.replace(
  //         //   `/home?${
  //         //     new URLSearchParams(history.location.search).get("s_id") || ""
  //         //   }`
  //         // );
  //       } else if (role === "scheduler") {
  //         history.replace("/scheduler-home");
  //       } else if (role === "technician") {
  //         history.replace("/technician-home");
  //       } else {
  //         history.replace("/");
  //       }
  //     }
  //   }
  // }, [user]);

  //admin-draft-requests
  /* RENDER APP
	---------------------------------------------------------------------------------*/
  return (
    <Switch>
      <AppRout
        path="/dashboard"
        exact
        component={DashboardAdmin}
        appProps={appProps}
        isAuth={user && user.role === "admin"}
        redirect_url={"/admin"}
      />
      <AppRout
        path="/customer-management"
        exact
        component={CustomerManagement}
        appProps={appProps}
        isAuth={user && user.role === "admin"}
        redirect_url={"/admin"}
      />
      <AppRout
        path="/customer-management/view-customer"
        exact
        component={ViewCustomer}
        appProps={appProps}
        isAuth={user && user.role === "admin"}
        redirect_url={"/admin"}
      />
      <AppRout
        path="/admin-draft-requests"
        exact
        component={DraftRequestsAdmin}
        appProps={appProps}
        isAuth={user && user.role === "admin"}
        redirect_url={"/admin"}
      />
      <AppRout
        path="/scheduler-management"
        exact
        component={SchedulerManagement}
        appProps={appProps}
        isAuth={user && user.role === "admin"}
        redirect_url={"/admin"}
      />
      <AppRout
        path="/scheduler-management/view-scheduler"
        exact
        component={ViewScheduler}
        appProps={appProps}
        isAuth={user && user.role === "admin"}
        redirect_url={"/admin"}
      />
      <AppRout
        path="/technician-management"
        exact
        component={TechnicianManagement}
        appProps={appProps}
        isAuth={user && user.role === "admin"}
        redirect_url={"/admin"}
      />
      <AppRout
        path="/technician-management/view-technician"
        exact
        component={ViewTechnician}
        appProps={appProps}
        isAuth={user && user.role === "admin"}
        redirect_url={"/admin"}
      />
      <AppRout
        path="/building-management"
        exact
        component={BuildingManagment}
        appProps={appProps}
        isAuth={user && user.role === "admin"}
        redirect_url={"/admin"}
      />
      <AppRout
        path="/building-management/view-building"
        exact
        component={ViewBuilding}
        appProps={appProps}
        isAuth={user && user.role === "admin"}
        redirect_url={"/admin"}
      />
      <AppRout
        path="/building-management/add-building"
        exact
        component={AddBuilding}
        appProps={appProps}
        isAuth={user && user.role === "admin"}
        redirect_url={"/admin"}
      />
      <AppRout
        path="/building-management/update-building/:buildingID"
        exact
        component={UpdateBuilding}
        appProps={appProps}
        isAuth={user && user.role === "admin"}
        redirect_url={"/admin"}
      />
      <AppRout
        path="/admin-follow-up-requests"
        exact
        component={FollowUpRequestsAdmin}
        appProps={appProps}
        isAuth={user && user.role === "admin"}
        redirect_url={"/admin"}
      />
      <AppRout
        path="/pin-management"
        exact
        component={PinManagement}
        appProps={appProps}
        isAuth={user && user.role === "admin"}
        redirect_url={"/admin"}
      />
      <AppRout
        path="/admin/order-detail"
        exact
        component={ViewOrderDetail}
        appProps={appProps}
        isAuth={user && user.role === "admin"}
        redirect_url={"/admin"}
      />
      <AppRout
        path="/admin-order-detail"
        exact
        component={ViewOrderDetailAdmin}
        appProps={appProps}
        isAuth={user && user.role === "admin"}
        redirect_url={"/admin"}
      />
      <AppRout
        path="/settings"
        exact
        component={SettingsManagement}
        appProps={appProps}
        isAuth={user && user.role === "admin"}
        redirect_url={"/admin"}
      />

      <AppRout
        path="/portal-documents"
        exact
        component={PortalDocuments}
        appProps={appProps}
        isAuth={user && user.role === "admin"}
        redirect_url={"/admin"}
      />
      <AppRout
        path="/invalid-comments-missing-units"
        exact
        component={InvalidCommentsMissingUnits}
        appProps={appProps}
        isAuth={user && user.role === "admin"}
        redirect_url={"/admin"}
      />

      <AppRout
        path={`/portal-documents/:folderID`}
        exact
        component={FolderDocuments}
        appProps={appProps}
        isAuth={user && user.role === "admin"}
        redirect_url={"/admin"}
      />

      <AppRout
        path="/home"
        exact
        component={Home}
        appProps={appProps}
        isAuth={user && user.role === "customer"}
        redirect_url={"/"}
      />
      <AppRout
        path="/buildingheatmapreport"
        exact
        component={Buildingheatmapreport}
        appProps={appProps}
        isAuth={true}
        // redirect_url={"/"}
      />
      <AppRout
        path="/building-history/:building_id"
        exact
        component={BuildingHistory}
        appProps={appProps}
        isAuth={user && user?.role === "scheduler" || user?.role === "customer"}
        redirect_url={"/"}
      />

      <AppRout
        path="/building-orders/:building_id"
        exact
        component={BuildingOrders}
        appProps={appProps}
        isAuth={user && user.role === "customer"}
        redirect_url={"/"}
      />

      <AppRout
        path="/order-request/:locationID"
        exact
        component={OrderRequestForm}
        appProps={appProps}
        isAuth={user && user.role === "customer"}
        redirect_url={"/"}
      />
      <AppRout
        path="/building-orders/automatic-followup-order-request/:order_id"
        exact
        component={OrderAutoFollowUp}
        appProps={appProps}
        isAuth={user && user.role === "customer"}
        redirect_url={"/"}
      />

      <AppRout
        path="/order-review"
        exact
        component={OrderReview}
        appProps={appProps}
        isAuth={user && user.role === "customer"}
        redirect_url={"/"}
      />
      <AppRout
        path="/order-detail"
        exact
        component={ViewOrderDetail}
        appProps={appProps}
        isAuth={user && user?.role === "scheduler" || user?.role === "customer"}
        redirect_url={"/"}
      />
      <AppRout
        path="/followup-requests"
        exact
        component={FollowUpRequests}
        appProps={appProps}
        isAuth={user && user.role === "customer"}
        redirect_url={"/"}
      />

      <AppRout
        path="/documents"
        exact
        component={CustomerPortalDocuments}
        appProps={appProps}
        isAuth={user && user.role === "customer"}
        redirect_url={"/"}
      />

      {/* Scheduler Routes */}
      <AppRout
        path="/"
        exact
        component={Login}
        appProps={appProps}
        isAuth={true}
      />
      <AppRout
        path="/scheduler"
        exact
        component={LoginScheduler}
        appProps={appProps}
        isAuth={true}
      />
      <AppRout
        path="/admin"
        exact
        component={AdminLogin}
        appProps={appProps}
        isAuth={true}
      />
      <AppRout
        path="/scheduler-home"
        exact
        component={SchedulerHome}
        appProps={appProps}
        isAuth={user && user.role === "scheduler"}
        redirect_url={"/scheduler"}
      />
      <AppRout
        path="/scheduler-home/update-order"
        exact
        component={OrderRequestFormScheduler}
        appProps={appProps}
        isAuth={user && user.role === "scheduler"}
        redirect_url={"/scheduler"}
      />
      <AppRout
        path="/building-heatmap/:building_id"
        exact
        component={BuildingHeatmap}
        appProps={appProps}
        isAuth={user && user.role === "scheduler"}
        redirect_url={"/scheduler"}
      /> 
      <AppRout
        path="/building-impersonate/:building_id"
        exact
        component={BuildingImpersonate}
        appProps={appProps}
        isAuth={user && user.role === "scheduler"}
        redirect_url={"/scheduler"}
      /> 
      <AppRout
        path="/building-impersonate/request-order/:building_id"
        exact
        component={ImpersonateOrderRequestForm}
        appProps={appProps}
        isAuth={user && user.role === "scheduler"}
        redirect_url={"/scheduler"}
      /> 
      <AppRout
        path="/building-impersonate/notes/:building_id"
        exact
        component={NOTES}
        appProps={appProps}
        isAuth={user && user.role === "scheduler"}
        redirect_url={"/scheduler"}
      /> 
      <AppRout
        path="/building-impersonate/order-review"
        exact
        component={ImpersonateOrderReview}
        appProps={appProps}
        isAuth={user && user.role === "scheduler"}
        redirect_url={"/scheduler"}
      /> 
      <AppRout
        path="/complete-order"
        exact
        component={CompleteOrder}
        appProps={appProps}
        isAuth={user && user.role === "scheduler"}
        redirect_url={"/scheduler"}
      />
      <AppRout
        path="/critical-section"
        exact
        component={CriticalList}
        appProps={appProps}
        isAuth={user && user.role === "scheduler"}
        redirect_url={"/scheduler"}
      />
      <AppRout
        path="/scheduler-order-draft"
        exact
        component={OrderRequestDraft}
        appProps={appProps}
        isAuth={user && user.role === "scheduler"}
        redirect_url={"/scheduler"}
      />

      <AppRout
        path="/schedule-orders/:order_id"
        exact
        component={ScheduleOrders}
        appProps={appProps}
        isAuth={user && user.role === "scheduler"}
        redirect_url={"/scheduler"}
      />

      {/* Technician Routes */}
      <AppRout
        path="/technician"
        exact
        component={TechLogin}
        appProps={appProps}
        isAuth={true}
      />
      <AppRout
        path="/technician-home"
        exact
        component={TechHome}
        appProps={appProps}
        isAuth={user && user.role === "technician"}
        redirect_url={"/technician"}
      />
      <AppRout
        path="/technician-home/view-building"
        exact
        component={ViewBuilding}
        appProps={appProps}
        isAuth={user && user.role === "technician"}
        redirect_url={"/technician"}
      />
      <AppRout
        path="/technician-home/order-request/:locationID"
        exact
        component={OrderRequestForm}
        appProps={appProps}
        isAuth={user && user.role === "technician"}
        redirect_url={"/technician"}
      />
      <AppRout
        path="/technician-home/order-review"
        exact
        component={OrderReview}
        appProps={appProps}
        isAuth={user && user.role === "technician"}
        redirect_url={"/technician"}
      />
      <AppRout
        path="/technician-home/order-detail"
        exact
        component={ViewOrderDetail}
        appProps={appProps}
        isAuth={user && user.role === "technician"}
        redirect_url={"/technician"}
      />
      <Route component={NotFound} />
    </Switch>
  );
}
